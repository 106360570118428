import React from 'react';
import './App.css';
import Header from './components/header/header-component';
import Footer from './components/footer/footer-component';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './components/router/routing';

function App() {
  return (
    <div className='App'>
      <Router>
        <Header />
        <Routing />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
