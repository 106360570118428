import React from 'react';
import './about-school.style.scss';
import { Paper } from '@material-ui/core';

import school from '../../assests/school.jpg';

const AboutSchool = () => {
  return (
    <div className='about-school-container'>
      <Paper className='about-school' elevation={3}>
        <img src={school} alt='School' />

        <h2>About the School</h2>
        <p>
          Manvasa Vidya Public School, Mangaptti, Sudhawai, Bhadohi is a
          developing English medium C.B.S.E affiliated school for quality
          education with special emphasis on character building and personality
          development on Islamic line. At Manvasa Vidya Public School are
          devoted to provide good quality education to students and inculcate
          morality in them towards other living beings. Manvasa Vidya Public
          School is dedicated to serve the mankind by fulfilling the following
          aims and objectives:
        </p>
        <p>
          To provide education to the students with a view to develop
          responsible, god-fearing and worthy citizens of motherland India{' '}
        </p>
        <p>
          To impart social, moral and cultural education in full conformity.{' '}
        </p>
        <p>
          To create qualities of initiative, entrepreneurship, team-work,
          self-reliance and competitive spirit among the students to build up a
          perfect character
        </p>
        <p>
          To collaborate in kindred activities with other national and
          international organizations, associations or institutions with similar
          objectives{' '}
        </p>
        <p>
          To develop a sense for personal hygiene and proper physical
          development among the students{' '}
        </p>
        <p>
          To provide instruction in various modern disciplines of knowledge and
          simultaneously see to it that the child's overall development.{' '}
        </p>
      </Paper>
    </div>
  );
};
export default AboutSchool;
