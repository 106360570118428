import React from "react";
import "./carriculam.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const CarriculamPage = () => {
  return (
    <div className="carriculam-container">
      <Paper className="carriculam" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Carriculam</PageHeading>
        <div>
          <br />
          <br />
           The course of study followed in the school is accordance with the
          syllabus prescribed by Central Board of Secondary Education (CBSE),
          New Delhi.
          <br />
          <br /> The medium of instruction is English simultaneously importance
          of Hindi is also applied to develop Motivation study, school
          environment &amp; to give creative teaching
        </div>
      </Paper>
    </div>
  );
};

export default CarriculamPage;
