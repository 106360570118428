import React, { useState } from "react";
import "./gallery.style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Thumbs,
  Autoplay,
} from "swiper";

//swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/thumbs/thumbs.scss";
import "swiper/components/a11y/a11y.scss";

//images
import i1 from "../../assests/gallery/1.jpg";
import i2 from "../../assests/gallery/2.jpg";
import i3 from "../../assests/gallery/3.jpg";
import i4 from "../../assests/gallery/4.jpg";
import i5 from "../../assests/gallery/5.jpg";
import i6 from "../../assests/gallery/6.jpg";
import i7 from "../../assests/gallery/7.jpg";
import i8 from "../../assests/gallery/8.jpg";
import i9 from "../../assests/gallery/9.jpg";
import i10 from "../../assests/gallery/10.jpg";
import i11 from "../../assests/gallery/11.jpg";
import i12 from "../../assests/gallery/12.jpg";
import i13 from "../../assests/gallery/13.jpg";
import i14 from "../../assests/gallery/14.jpg";
import i15 from "../../assests/gallery/15.jpg";
import i16 from "../../assests/gallery/16.jpg";
import i17 from "../../assests/gallery/17.jpg";
import i18 from "../../assests/gallery/18.jpg";
import i19 from "../../assests/gallery/19.jpg";
import i20 from "../../assests/gallery/20.jpg";
import i21 from "../../assests/gallery/21.jpg";
import i22 from "../../assests/gallery/22.jpg";
import i23 from "../../assests/gallery/23.jpg";
import i24 from "../../assests/gallery/24.jpg";
import i25 from "../../assests/gallery/25.jpg";

SwiperCore.use([Navigation, Pagination, A11y, Thumbs, Autoplay]);

const Gallery = () => {
  const imageArr = [
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
    i18,
    i19,
    i20,
    i21,
    i22,
    i23,
    i24,
    i25,
  ];
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <main className="gallery-container">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        navigation
        autoplay={{ delay: 10000 }}
        thumbs={{ swiper: thumbsSwiper }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {imageArr.map((image) => {
          return (
            <SwiperSlide style={{ display: `flex`, justifyContent: `center` }}>
              <img className="gallery-full-image" src={image} alt="slide" />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper onSwiper={setThumbsSwiper} spaceBetween={0.1} slidesPerView={5}>
        {imageArr.map((image) => {
          return (
            <SwiperSlide>
              <img src={image} alt="thumb slide" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </main>
  );
};

export default Gallery;
