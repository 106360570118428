import React from "react";
import "./play-ground.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const PlayGroundPage = () => {
  return (
    <div className="play-ground-container">
      <Paper className="play-ground" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Play Ground</PageHeading>
        <div>
          <br />
          <br />
          Manvasa Vidya Public School believes "Healthy body breeds healthy
          mind" and show diligence for perfection in everything, including
          sports. Children need physical activities as much as they need high
          academic achievements to ensure synchronous physical, emotional,
          intellectual and academic growth.
          <br />
          <br />
          Manvasa Vidya Public School has a big play Ground in school campus.
          Every child gets an opportunity to participate in competitive
          athletics and Co-Scholastics activities. On every Saturday inter House
          Competitions are organised to assess their performance in the
          respective areas.
        </div>
      </Paper>
    </div>
  );
};

export default PlayGroundPage;
