import React from "react";
import "./class-rooms.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const ClassRoomsPage = () => {
  return (
    <div className="class-rooms-container">
      <Paper className="class-rooms" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Class Rooms</PageHeading>

        <div>
          <br />
          <br />
          School is equipped with ICT classrooms that enable the child to
          visualise the concepts studied. Children understand concepts better
          using the visuals in the classroom. Themes selected at each level of
          study are integrated with the ICT experience and teachers use visual
          learning as an effective tool to build on the understanding of the
          child. This way the interest of the child is also sustained in the
          classroom.
          <br />
          <br />
          <strong>Smart Class</strong>
          <br />
          <br />
          The school has made available to the students a powerful smart class
          application engine that allows the teachers to view room session as
          per syllabus from kindergarten to secondary class. The purpose is to
          make the learning easier and faster by bringing technology.
        </div>
      </Paper>
    </div>
  );
};

export default ClassRoomsPage;
