import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AboutSchool from '../about-school/about-school-component';
import HomePage from '../../pages/homepage/homepage';
import ContactInfo from '../../pages/contact-info/contact';
import Inquiry from '../../pages/inquiry/inquiry';
import GalleryPage from '../../pages/gallery/gallery';
import { GridGallery } from '../grid-gallery/grid-gallery-component';
import AcademicSystemPage from '../../pages/academic-system/academic-system';
import AdmissionProceduresPage from '../../pages/admission-procedures/admission-procedures';
import CarriculamPage from '../../pages/carriculam/carriculam';
import CoCarricularActivityPage from '../../pages/co-carricular-activity/co-carricular-activity';
import ClassRoomsPage from '../../pages/class-rooms/class-rooms';
import SchoolLibraryPage from '../../pages/school-library/school-library';
import SchoolLabPage from '../../pages/school-lab/school-lab';
import PlayGroundPage from '../../pages/play-ground/play-ground';
import InfrastructuralHighlightsPage from '../../pages/infrastructural-highlights/infrastructural-highlights';
import Mandatory from '../../pages/mandatory/mandatory';

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage}></Route>
      <Route path="/about" component={AboutSchool}></Route>
      <Route path="/academic-system" component={AcademicSystemPage}></Route>
      <Route
        path="/admission-procedures"
        component={AdmissionProceduresPage}
      ></Route>
      <Route path="/carriculam" component={CarriculamPage}></Route>
      <Route
        path="/co-carricular-activity"
        component={CoCarricularActivityPage}
      ></Route>

      <Route
        path="/infrastructural-highlights"
        component={InfrastructuralHighlightsPage}
      ></Route>
      <Route path="/class-rooms" component={ClassRoomsPage}></Route>
      <Route path="/contact-info" component={ContactInfo}></Route>
      <Route path="/school-library" component={SchoolLibraryPage}></Route>
      <Route path="/school-lab" component={SchoolLabPage}></Route>
      <Route path="/play-ground" component={PlayGroundPage}></Route>

      <Route path="/gallery" component={GalleryPage}></Route>
      <Route path="/grid-gallery" component={GridGallery}></Route>
      <Route path="/mandatory" component={Mandatory}></Route>

      <Route path="/inquiry" component={Inquiry}></Route>
    </Switch>
  );
};

export default Routing;
