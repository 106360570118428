import React from "react";
import "./school-lab.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";
import labImg from "../../assests/gallery/21.jpg";

const SchoolLabPage = () => {
  return (
    <div className="school-lab-container">
      <Paper className="school-lab" elevation={3}>
        <PageHeading marginLeft="-3.2rem">School Labs</PageHeading>
        <br />
        <img src={labImg} alt="school library" className="lab"></img>

        <div>
          <br />
          <br />
          <strong>Science Labs</strong>
          <br />
          <br />
          The school has well equipped Physics, Chemistry &amp; Biology Lab with
          the latest apparatus to accommodate whole class at a time.
          <br />
          <br />
          <strong>Computer Lab</strong>
          <br />
          <br />
          (Age of computer &amp; Information Technology) Computerization has
          become a global phenomenon. The world is leaping into new vistas of
          progress and development with the application of computer technology.
          Computer science has been a part of school curriculum from the
          beginning itself with the intention of accelerating a creative
          interest in modern technology and scientific Knowledge. Fully equipped
          with latest state of Art, Computer Software, Hardware and Networking.
          Ly is actively used under the guidance of well qualified and
          experienced staff for imparting computer skills and Knowledge to the
          students.
        </div>
      </Paper>
    </div>
  );
};

export default SchoolLabPage;
