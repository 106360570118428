import React from 'react';
import { TextField, Button, Paper, Typography } from '@material-ui/core';
import './inquiry.scss';
import PageHeading from '../../components/page-heading/page-heading-component';
const Inquiry = () => {
  return (
    <div className='inquiry-container'>
      <Paper className='inquiry-paper' variant='3'>
        <PageHeading>Send Enquiry</PageHeading>
        <Typography
          varient='h1'
          style={{ color: '#d5098e', marginTop: '1rem' }}
        >
          Please Post your quick enquiry related to any question.We will reply
          as soon as possible.
        </Typography>
        <form noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            fullwidth
            required
            label='Full Name'
            name='name'
          />
          <TextField
            variant='outlined'
            margin='normal'
            fullwidth
            required
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            fullwidth
            required
            label='Mobile Number'
            name='mobile'
          />
          <TextField
            variant='outlined'
            margin='normal'
            fullwidth
            required
            label='Question Related to'
            name='question'
          />
          <TextField
            id='outlined-multiline-static'
            label='Details'
            multiline
            margin='normal'
            rows={5}
            defaultValue=''
            variant='outlined'
          />

          <Button
            type='submit'
            onSubmit={(event) => event.preventDefault()}
            variant='contained'
            style={{
              background: '#5a319b',
              color: `whitesmoke`,
              padding: `.5rem`,
              marginTop: `1rem`,
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default Inquiry;
