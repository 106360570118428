import React from 'react';
import './footer.style.scss';

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <footer>
      <h4>Copyright &copy; {year} Manvasa Vidya Public School </h4>
    </footer>
  );
};

export default Footer;
