import React from 'react';
import './contact.scss';
import { Paper } from '@material-ui/core';
import PageHeading from './../../components/page-heading/page-heading-component';

const ContactInfo = () => {
  return (
    <div className='contact-info-container'>
      <Paper className='contact-info' elevation={3}>
        <PageHeading marginLeft='-3.2rem'>Contact Us</PageHeading>
        <h3>Manvasa Vidya Public School</h3>
        <table className='info__table'>
          <tr>
            <th>Address:</th>
            <td>Mangaptti, Sudhawai, Bhadohi, 221308 Uttar Pradesh</td>
          </tr>

          <tr>
            <th>Phone:</th>
            <td>9935228262, 9793966654</td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>mvpublics@gmail.com, manvasa.org@gmail.com</td>
          </tr>
          <tr>
            <th>Website:</th>
            <td>https://www.mvps.in</td>
          </tr>
        </table>
      </Paper>
    </div>
  );
};

export default ContactInfo;
