import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import Img from "./img.component";
import "./carousels.style.scss";
//styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

//images
import i1 from "../../assests/slider/1.jpg";
import i2 from "../../assests/slider/2.jpg";
import i3 from "../../assests/slider/3.jpg";
import i4 from "../../assests/slider/4.jpg";
import i5 from "../../assests/slider/5.jpg";
import i6 from "../../assests/slider/6.jpg";
import i7 from "../../assests/slider/7.jpg";
import i8 from "../../assests/slider/8.jpg";
import i9 from "../../assests/slider/9.jpg";
import i10 from "../../assests/slider/10.jpg";
import i11 from "../../assests/slider/11.jpg";
import i12 from "../../assests/slider/12.jpg";
import i13 from "../../assests/slider/13.jpg";
import i14 from "../../assests/slider/14.jpg";
import i15 from "../../assests/slider/15.jpg";
import i16 from "../../assests/slider/16.jpg";
import i17 from "../../assests/slider/17.jpg";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const Carousels = () => {
  const imageArr = [
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
  ];

  return (
    <Swiper
      className="hero-slider"
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      {imageArr.map((image) => {
        return (
          <SwiperSlide>
            <Img src={image} alt="slide" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousels;
