import React, { useState, useEffect } from "react";
import "./grid-gallery.style.scss";

import i1 from "../../assests/gallery/1.jpg";
import i2 from "../../assests/gallery/2.jpg";
import i3 from "../../assests/gallery/3.jpg";
import i4 from "../../assests/gallery/4.jpg";
import i5 from "../../assests/gallery/5.jpg";
import i6 from "../../assests/gallery/6.jpg";
import i7 from "../../assests/gallery/7.jpg";
import i8 from "../../assests/gallery/8.jpg";
import i9 from "../../assests/gallery/9.jpg";
import i10 from "../../assests/gallery/10.jpg";
import i11 from "../../assests/gallery/11.jpg";
import i12 from "../../assests/gallery/12.jpg";
import i13 from "../../assests/gallery/13.jpg";
import i14 from "../../assests/gallery/14.jpg";
import i15 from "../../assests/gallery/15.jpg";
import i16 from "../../assests/gallery/16.jpg";
import i17 from "../../assests/gallery/17.jpg";
import i18 from "../../assests/gallery/18.jpg";
import i19 from "../../assests/gallery/19.jpg";
import i20 from "../../assests/gallery/20.jpg";
import i21 from "../../assests/gallery/21.jpg";
import i22 from "../../assests/gallery/22.jpg";
import i23 from "../../assests/gallery/23.jpg";
import i24 from "../../assests/gallery/24.jpg";
import i25 from "../../assests/gallery/25.jpg";

export const GridGallery = () => {
  const images = [
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
    i18,
    i19,
    i20,
    i21,
    i22,
    i23,
    i24,
    i25,
  ];

  const [imageSrc, setImageSrc] = useState("");
  const selectImage = (e) => {
    setImageSrc(e.target.src);
  };
  const handleClose = (e) => {
    const image = document.querySelector(".fullImage");
    if (e.target.className !== image.className) {
      setImageSrc("");
    }
  };

  useEffect(() => {
    const galleryImages = document.querySelectorAll(".gallery-images");

    galleryImages.forEach((image) => {
      if (image.width < image.height) {
        image.parentElement.classList.add("v2");
        image.style.height = "100%";
      } else {
        image.style.height = "100%";
      }
    });
  }, [imageSrc]);

  return (
    <React.Fragment>
      <h1
        style={{
          fontSize: `minmax(1.5rem,3rem) !important`,
          color: `#5a319b`,
          borderBottom: `2px solid #d5098e`,
          paddingBottom: `5px`,
          width: `150px`,
          textAlign: `center`,
          margin: `0 auto`,
        }}
      >
        Gallery
      </h1>
      <div className="grid-gallery-container">
        {images.map((image) => {
          return (
            <div className="item">
              <img
                className="gallery-images"
                src={image}
                key={Math.random()}
                alt="gallery"
                onClick={selectImage}
              />
            </div>
          );
        })}
      </div>
      {imageSrc ? (
        <div className="overlay" onClick={(e) => handleClose(e)}>
          <img src={imageSrc} alt="nothing" className="fullImage" />
        </div>
      ) : null}
    </React.Fragment>
  );
};
