import React from 'react';
import './mandatory.scss';
import { Paper } from '@material-ui/core';
import PageHeading from '../../components/page-heading/page-heading-component';

const SchoolDetails = () => {
  return (
    <Paper className="mandatory-container">
      <PageHeading marginLeft={`-3.2rem`}>
        Mandatory Public Disclosure:
      </PageHeading>

      {
        // A. General Information
      }
      <h4>A. GENERAL INFORMATION:</h4>
      <table cellspacing="2" cellpadding="3">
        <tbody>
          <tr>
            <th>SL No.</th>
            <th>Information</th>
            <th>Details</th>
          </tr>
          <tr>
            <td>1</td>
            <td>NAME OF THE SCHOOL</td>
            <td>&nbsp;MANVASA VIDYA PUBLIC SCHOOL</td>
          </tr>
          <tr>
            <td>2</td>
            <td>AFFILIATION NO</td>
            <td>&nbsp;2133587</td>
          </tr>
          <tr>
            <td>3</td>
            <td>SCHOOL CODE</td>
            <td>&nbsp;71739</td>
          </tr>
          <tr>
            <td>4</td>
            <td>COMPLETE ADDRESS WITH PIN CODE</td>
            <td>&nbsp;MANGAPATTI SUDHAWAI BHADOHI U.P. 221308</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PRINCIPAL NAME</td>
            <td>&nbsp;01</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PRINCIPAL QUALIFICATION</td>
            <td>&nbsp;M.A., B.ed.</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SCHOOL EMAIL ID</td>
            <td>&nbsp;mvpublics@gmail.com</td>
          </tr>
          <tr>
            <td>8</td>
            <td>CONTACT US</td>
            <td>&nbsp;8299765951, 9935228262</td>
          </tr>
        </tbody>
      </table>

      {
        // B. DOCUMENTS AND INFORMATION
      }
      <br />
      <br />
      <h4>B. DOCUMENTS AND INFORMATION:</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>DOCUMENTS / INFORMATION</th>
            <th>UPLOAD DOCUMENTS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF
              AFFILIATION, IF ANY
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Manvasa+Vidya+Public+School+Affiliation.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              COPY SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS
              APPLICABLE
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Trust+Original.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE , BY
              THE STATE GOVT/ UT
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/JD%26+COMISNOR+NOC.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              COPY OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S
              RENEWAL IF APPLICABLEE
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Manyata+BSA.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              COPY OF BUILD BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL
              BUILDING CODE
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/National+Building+Code.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT
              AUTHORITY
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Fire+Safety.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR
              AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATIONOR SELF
              <br />
              CERTIFICATION BY SCHOOL
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Self+Certification.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>COPY OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Safe+Drinking+And+Sanitary+123.pdf">
                VIEW
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <p>
        <strong>NOTE:</strong> THE SCHOOLS NEEDS TO UPLOAD THE SELF ATTESTED
        COPIES OF ABOVE LISTED DOCUMETNS BY CHAIRMAN/MANAGER/SECRETARY AND
        PRINCIPAL. IN CASE, IT IS NOTICED AT LATER STAGE THAT UPLOADED DOCUMENTS
        ARE NOT GENUINE THEN SCHOOL SHALL BE LIABLE FOR ACTION AS PER NORMS.
      </p>
      {
        // C. RESULT AND ACADEMICS
      }
      <br />
      <h4>C. RESULT AND ACADEMICS:</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>DOCUMENTS / INFORMATION</th>
            <th>UPLOAD DOCUMENTS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>FEE STRUCTURE OF THE SCHOOL</td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Fee+Structure+2023-24+(1).pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>ANNUAL ACADEMIC CALENDER</td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/MV+Public+Calander+2023-24.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/School+Management+Committee.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Parents+Teacher+Association.pdf">
                VIEW
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
              APPLICABILITY
            </td>
            <td>
              <a href="https://manvasa.s3.ap-south-1.amazonaws.com/mandatory/Result+Class+X.pdf">
                VIEW
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      {
        // D. STAFF (TEACHING)
      }
      <br />
      <br />
      <h4>D. STAFF (TEACHING):</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>PRINCIPAL</td>
            <td>01</td>
          </tr>
          <tr>
            <td>2</td>
            <td>TOTAL NO. OF TEACHERS</td>
            <td>22</td>
          </tr>
          <tr>
            <td></td>
            <td>PGT</td>
            <td>09</td>
          </tr>
          <tr>
            <td></td>
            <td>TGT</td>
            <td>10</td>
          </tr>
          <tr>
            <td></td>
            <td>PRT</td>
            <td>03</td>
          </tr>
          <tr>
            <td>3</td>
            <td>TEACHERS SECTION RATIO</td>
            <td>1.5:1</td>
          </tr>
          <tr>
            <td>4</td>
            <td>DETAILS OF SPECIAL EDUCATOR</td>
            <td>01</td>
          </tr>
          <tr>
            <td>5</td>
            <td>DETAILS OF COUNSELOR AND WELLNESS TEACHER</td>
            <td>01</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />
      <h4>RESULT CLASS: X</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>YEAR</th>
            <th>NO. OF REGISTERED STUDENTS</th>
            <th>NO. OF STUDENTS PASSED</th>
            <th>PASS PERCENTAGE</th>
            <th>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>2023</td>
            <td>194</td>
            <td>191</td>
            <td>98%</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />
      <h4>RESULT CLASS: XII</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>YEAR</th>
            <th>NO. OF REGISTERED STUDENTS</th>
            <th>NO. OF STUDENTS PASSED</th>
            <th>PASS PERCENTAGE</th>
            <th>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>2023</td>
            <td>104</td>
            <td>87</td>
            <td>84%</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      {
        // E. SCHOOL INFRASTRUCTURE:
      }
      <br />
      <br />
      <h4>E. SCHOOL INFRASTRUCTURE:</h4>
      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</td>
            <td>6200</td>
          </tr>
          <tr>
            <td>2</td>
            <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ MTR)</td>
            <td>14 &amp; 47</td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQ MTR)
            </td>
            <td>06 &amp; 56</td>
          </tr>
          <tr>
            <td>4</td>
            <td>INTERNET FACILITY (Y/N)</td>
            <td>YES</td>
          </tr>
          <tr>
            <td>5</td>
            <td>NO. OF GIRLS TOILETS</td>
            <td>08</td>
          </tr>
          <tr>
            <td>6</td>
            <td>NO. OF BOYS TOILETS</td>
            <td>08</td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING
              INFRASTRUCTURE OF THE SCHOOL
            </td>
            <td>
              <a href="https://youtube.com/watch?v=19nbum7_JVw&feature=share">VIEW</a>
            </td>
          </tr>
        </tbody>
      </table>
    </Paper>
  );
};

export default SchoolDetails;
