import React from 'react';
import './homepage.scss';

import Carousels from '../../components/carousels/carousels.component';

import PrincipalMessage from '../../components/principal-message/principal-message-component';

const HomePage = () => {
  return (
    <React.Fragment>
      <Carousels />
      <div className='container-homepage'>
        <PrincipalMessage />
      </div>
    </React.Fragment>
  );
};
export default HomePage;
