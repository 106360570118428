import React from "react";
import "./infrastructural-highlights.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const InfrastructuralHighlightsPage = () => {
  return (
    <div className="infrastructural-highlights-container">
      <Paper className="infrastructural-highlights" elevation={3}>
        <PageHeading marginLeft="-3.2rem">
          Infrastructural Highlights
        </PageHeading>
        <div>
          <br />
          <br />
          <p>
             Magnificent school building.
            <br />
            <br />
             Well equipped modern science laboratory.
            <br />
            <br />
             highly equipped modern computer lab.
            <br />
            <br />
             Book and stationary outlets.
            <br />
            <br />
             Sports ground provides volley ball, badminton etc.
            <br />
            <br />
             Canteen.
            <br />
            <br />
             Medical facilities.
            <br />
            <br />
             Career counseling cell.
            <br />
            <br /> Transport facilities.
          </p>
        </div>
      </Paper>
    </div>
  );
};

export default InfrastructuralHighlightsPage;
