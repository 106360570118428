import React from 'react';
import './page-heading.style.scss';

const PageHeading = (props) => {
  return (
    <h2 className='page-heading' style={{ marginLeft: props.marginLeft }}>
      {props.children}
    </h2>
  );
};

export default PageHeading;
