import React, { useState } from 'react';
import './header.style.scss';
import { NavLink } from 'react-router-dom';
import SchoolBaner from './../../assests/SchoolBanner.png';
import inq from './../../assests/inq.png';

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);

  const toggleMenu = () => {
    const menuBtn = document.querySelector('.menu-icon');
    const nav = document.querySelector('.main-menu');
    if (!menuToggle) {
      menuBtn.classList.toggle('open');
      nav.classList.toggle('open');
      setMenuToggle(true);
    } else {
      menuBtn.classList.toggle('open');
      nav.classList.toggle('open');
      setMenuToggle(false);
    }
  };
  return (
    <React.Fragment>
      <div className="header">
        <img alt="School Logo" className="logo" src={SchoolBaner} />
        <img alt="Quick Inquiry" className="inq" src={inq} />
      </div>
      <div className="menu-icon">
        <i className="fas fa-bars" onClick={toggleMenu}></i>
        <i className="fas fa-times" onClick={toggleMenu}></i>
      </div>

      <ul className="main-menu">
        <li>
          <NavLink to="/">HOME</NavLink>
        </li>
        <li>
          <NavLink to="/about">ABOUT US</NavLink>
        </li>
        <li>
          <NavLink to="#">
            ACADEMICS <i className="fas fa-angle-down"></i>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="/academic-system">Academic System</NavLink>
            </li>
            <li>
              <NavLink to="/admission-procedures">Admission Procedure</NavLink>
            </li>
            <li>
              <NavLink to="/carriculam">Cariculum</NavLink>
            </li>
            <li>
              <NavLink to="/co-carricular-activity">
                Co-curricular Activity
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="#">
            SCHOOL CAMPUS<i className="fas fa-angle-down"></i>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="/infrastructural-highlights">
                Infrastructural Highlights
              </NavLink>
            </li>
            <li>
              <NavLink to="/class-rooms">Class Room</NavLink>
            </li>
            <li>
              <NavLink to="/school-library">School Library</NavLink>
            </li>
            <li>
              <NavLink to="/school-lab">School Lab</NavLink>
            </li>
            <li>
              <NavLink to="/play-ground">Play Ground</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/gallery">PHOTO GALLERY</NavLink>
        </li>
        <li>
          <NavLink to="/mandatory">MANDETORY</NavLink>
        </li>
        <li>
          <NavLink to="#">
            CONTACTS<i className="fas fa-angle-down"></i>
          </NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to="/contact-info">Contact Info</NavLink>
            </li>
            <li>
              <NavLink to="/inquiry">Inquiry</NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Header;
