import React from "react";

const Img = ({ src }) => {
  let imgStyles = {
    width: `100vw`,
    height: `80vh`,
  };

  return (
    <React.Fragment>
      <img src={src} alt="slide-img" style={imgStyles}></img>
    </React.Fragment>
  );
};

export default Img;
