import React from "react";
import "./admission-procedures.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const AdmissionProceduresPage = () => {
  return (
    <div className="admission-procedures-container">
      <Paper className="admission-procedures" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Admission Procedures</PageHeading>
        <div>
          <br />
          <br />
          1. Registration Forms are to be filled and submitted to the school
          office within seven days of issue of Registration Forms.
          <br />
          <br />
          2. Registration does not imply admission which is subject to admission
          test/observation and interaction and the availability of seats.
          <br />
          <br />
          3. Incomplete Registration Forms without photograph will be considered
          invalid.
          <br />
          <br />
          4. Date for tests/observation and interaction will be given at the
          time of Registration and the same may be changed, on a very short
          notice of only two days by the school.
          <br />
          <br />
          5. At the time of Registration the following documents along with
          Registration and processing Fee In cash are to be submitted to the
          school office.
          <br />
          <br />
          • Duly filled and signed Registration Forms.
          <br />• Color passport size photograph.
          <br />• Photocopy of proof of date of birth.
          <br />• Affidavit of no. of sons and daughters.
          <br />
          <br />
          6. Admission to Nursery and Preparatory classes will be through
          observation.
          <br />
          <br />
          7. There will be an interview for students seeking admission to
          classes I-VIII followed by a written test.
          <br />
          <br />
          8. It is strongly advised that the parents should fill the
          Registration Forms themselves.
          <br />
          <br />
          9. If T.C. with ‘Passed’ or ‘Promoted’ written on it is not submitted
          of the earlier school in time.
        </div>
      </Paper>
    </div>
  );
};

export default AdmissionProceduresPage;
