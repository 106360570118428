import React from "react";
import "./school-library.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

import schoolLibrary from "../../assests/library.jpg";

const SchoolLibraryPage = () => {
  return (
    <div className="school-library-container">
      <Paper className="school-library" elevation={3}>
        <PageHeading marginLeft="-3.2rem">School Library</PageHeading>
        <br />
        <img src={schoolLibrary} alt="school library" className="library"></img>

        <div>
          <br />
          The library helps the children to upgrade &amp; update their Knowledge
          and reinforce their class room learning.
          <br />
          <br />
          School offers its student s on well-stocked, spacious Library to
          quench the children’s intellectual carving. The treasure trove of our
          library are illustrated encyclopedias, books, story books, curriculum
          base additional books, selected daily newspaper, weekly and monthly
          magazines and periodicals.
          <br />
          <br />A number of magazines and newspapers are subscribed, to enhance
          the general awareness of the students. Library periods are integrated
          into the regular timetable of the school. Storytelling, book reading
          and library week are organized on a regular basis.
        </div>
      </Paper>
    </div>
  );
};

export default SchoolLibraryPage;
