import React from "react";
import "./principal-message.style.scss";
import { Paper } from "@material-ui/core";

const PrincipalMessage = () => {
  return (
    <Paper className="principal-message" elevation={3}>
      <h2>Message From the Principal</h2>
      <p>Dear Parents,</p>
      <p>
        We welcome you into our world at Manvasa Vidya Public School Mangapatti
        Sudhawai Bhadohi, an institution that promotes meaningful and joyful
        learning, a school where scholastic and co-scholastic activities are
        closely interwoven into the curricular fabric, a home away from home
        where students and teachers share a relationship of mutual trust,
        respect, warmth, and affection.
      </p>
      <p>
        A Continuous and Comprehensive Evaluation system CCE caters to the
        holistic development of children integrating sports, fine arts, yoga &
        martial arts, environmental awareness, values and attitudes, life skills
        and technology with scholastic subjects. Students’ assessment is based
        on 21st century skill development.
      </p>
      <p>
        An inclusive and progressive school like Manvasa Vidya Public School
        lays strong emphasis on personalization, meeting diverse needs,
        providing equal opportunities for learning and maintaining high
        standards of achievement at all levels. The secure environment of
        Manvasa Vidya Public School provides children with opportunities to
        learn, explore, play, discover their hidden talents and make informed
        choices.
      </p>
      <p>
        Schooling by nature is a social and cultural undertaking. As the world
        shrinks and barriers are broken, our students join hands with their
        counterparts across the country & the world by working together on
        multiple areas. Through international exchange programmes, social
        responsibility initiatives, human rights issues, environment
        conservation programmes, cultural exchange programmes and projects, they
        learn invaluable life lessons on diversity, equity, tolerance and
        acceptance.
      </p>
      <p>
        Empowered with a strong sense of wellbeing, we are confident that our
        students will emerge as responsible global leaders and citizens with
        strong moral, ethical and social values.
      </p>
    </Paper>
  );
};
export default PrincipalMessage;
