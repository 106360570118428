import React from "react";
import "./co-carricular-activity.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const CoCarricularActivityPage = () => {
  return (
    <div className="co-carricular-activity-container">
      <Paper className="co-carricular-activity" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Co-carricular Activity</PageHeading>
        <div>
          <br />
          <br />
          To ensure an all round development of the mind and physique and to
          provide training in every sphere to the students, the under mentioned
          activities are regular features of the school curriculum.
          <br />
          <br /> 1. Debate
          <br />
          2. Role Play
          <br />
          3. Drawing &amp; Painting
          <br />
          4. Symposium
          <br />
          5. Candle Making
          <br />
          6. Essay Writing
          <br />
          7. Recitation
          <br />
          8. Music Competitions
          <br />
          9. Yoga &amp; Aerobics
          <br />
          10. Extempore
          <br />
          11. Seminars
          <br />
          12. Clay Modeling
          <br />
          13. Creative writing
          <br />
          14. Quiz
          <br />
          15. Community Service
          <br />
          16. Dance (Indian &amp; Western)
          <br />
          17. Group Discussions
          <br />
          18. Pantomime
          <br />
          19. Dramatics
          <br />
          20. Art &amp; Craft/Caricatures
        </div>
      </Paper>
    </div>
  );
};

export default CoCarricularActivityPage;
