import React from "react";
import "./academic-system.style.scss";
import { Paper } from "@material-ui/core";
import PageHeading from "./../../components/page-heading/page-heading-component";

const AcademicSystemPage = () => {
  return (
    <div className="academic-sytem-container">
      <Paper className="academic-system" elevation={3}>
        <PageHeading marginLeft="-3.2rem">Academic System</PageHeading>
        <div>
          <strong>
            <br />
            EXAMINATION &amp; ITS CRITERIA
            <br />
          </strong>
          We do follow all the techniques and tools to bring all round
          development among the individuals apart from pen paper test for which
          we organise different types of workshops, competitions, seminars etc.
          to provide the strength to CCE framed by the C.B.S.E..
          <br />
          <br />
          <strong>
            FACULTIES
            <br />
          </strong>
          This institution is proud of its highly qualified and talented
          teachers selected from all parts of the country. Their invaluable
          service and sacrifice are praiseworthy as they have successfully
          impressed upon the young minds for the importance of education, social
          duties, and national awareness.
          <br />
          <br />
          <br />
          <strong>
            OUR ACHIEVEMENT &amp; AWARDS
            <br />
          </strong>
          Academic Excellence Framework and discovering our approach to
          increased focus, collaboration, innovation, and accountability are our
          commitment. We believe this Framework provides a roadmap as we
          continue on a journey towards the academic excellence. Every year we
          are not leaving any stone unturned due which glories are coming in to
          the path of Boonmax Convent School and improving in academics each
          year.
        </div>
      </Paper>
    </div>
  );
};

export default AcademicSystemPage;
